import { getCookie, setCookie, clearCookie } from "./cookieUtils";

const hostname = window.location.hostname.split(".").slice(-2).join(".");
const domain = process.env.NODE_ENV === "production" ? hostname : "localhost";

const accessToken = "adminAccessToken";
export const setAccessToken = (token) =>
  setCookie(accessToken, token, 30, domain);

export const getAccessToken = () => getCookie(accessToken);
export const removeAccessToken = () => {
  clearCookie(accessToken, domain);
};
