import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { PublicRoute } from "./base/PublicRoute";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/LMS/Auth/Login"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <ToastContainer pauseOnFocusLoss={false} />
        <React.Suspense fallback={loading()}>
          <Switch>
            <PublicRoute
              path="/lms/login"
              name="Admin Login"
              component={Login}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
